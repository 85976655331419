// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const URL = 'https://portal.intersystems.p.isccloud.io';
const STAGE = 'intersystems';
const ICCA_STAGE = 'sales';
const ICCA_ACCOUNT = 'sandbox-eng-paas';

export const environment = {
  production: true, //either true or false
  NG_APP_COGNITO_USERPOOL_ID: 'us-east-2_3U2ngZ20A',
  NG_APP_COGNITO_CLIENT_ID: '6rquusqttjcg7l0h47g95a2046',
  NG_APP_COGNITO_REGION: 'us-east-2',
  APP_URL: `${URL}`,
  API_URL: `${URL}/v3/api`,
  STAGE: `${STAGE}`,
  ICCA_STAGE: `${ICCA_STAGE}`,
  ICCA_ACCOUNT: `${ICCA_ACCOUNT}`,
  ICCA_REGIONS: ['us-east-1'],
  HIDDEN_OFFERS: ['doc', 'sds', 'fhirserver'],
};
